<template>
  <div>
    <div class="demoWrap">
      <transition name="fade">
        <div v-if="!isAuth" class="dim on">
          <RequestDemo />
        </div>
      </transition>
      <div class="demoRight">
        <div class="demoVideo">
          <h2>Reviewmind for Business</h2>
          <!-- <iframe
            src="https://reviewmindbucket.s3.ap-northeast-2.amazonaws.com/reviewmindDemo.mp4"
            iframe
          ></iframe>-->
          <video controls>
            <source
              src="https://reviewmindbucket.s3.ap-northeast-2.amazonaws.com/reviewmindDemo.mp4"
              type="video/mp4"
            />
          </video>
          <p>
            We train NLP-powered AI models to understand your customers!
            <br />
            <br />[리뷰마인드]는 AI 및 NLP 기술 기반의 자동 리뷰
            분석/관리/마케팅 서비스를 SaaS 솔루션 형태로 제공하는 벤처
            기업입니다. 저희 서비스를 이용하시면 귀사의 제품, 서비스, 및
            브랜드에 대한 고객들의 감성, 선호도, 제안 아이디어를 보다 빠르고
            편리하며 일관성있고 또 정확하게 확인하고 대응하실 수 있습니다.
          </p>
          <a href="tel:02-713-5847">
            <i class="fas fa-phone-alt"></i> 02-713-5847
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RequestDemo from "@/components/pop/RequestDemo";
// import { requestDemo } from "@/api/index";
import { eventBus } from "@/main";
// import { createCertNumber, verifyMobile } from "@/api/member";
import Cookies from "js-cookie";

export default {
  components: {
    RequestDemo,
  },
  data() {
    return {
      isAuth: false,
      company: "",
      name: "",
      mobileNo: "",
      email: "",
      verifyCodeVisible: false,
      certNumber: "",
      verify: false,
    };
  },
  created() {
    this.$store.dispatch("GNB_MENU", 7);
  },
  mounted() {
    eventBus.$on("closeModal", () => {
      this.isAuth = true;
    });
    this.isAuth = Cookies.get("DemoVerify");
  },
  methods: {
    // requestCertNumber() {
    //   if (this.mobileNo == "") {
    //     return alert("번호를 입력해주세요.");
    //   }
    //   let data = {
    //     mobileNo: this.mobileNo,
    //   };
    //   this.verifyCodeVisible = true;
    //   createCertNumber(data).then((res) => {
    //     if (res.status == 200) {
    //       alert(res.data.message);
    //     } else {
    //       alert(res.data.message);
    //     }
    //   });
    // },
    // verifyNumber() {
    //   let data = {
    //     mobileNo: this.mobileNo,
    //     certNumber: this.certNumber,
    //   };
    //   verifyMobile(data).then((res) => {
    //     if (res.data.status == 200) {
    //       this.verify = true;
    //       alert(res.data.message);
    //     } else {
    //       this.verify = false;
    //       alert(res.data.message);
    //     }
    //   });
    // },
    // submit() {
    //   let data = {
    //     company: this.company,
    //     name: this.name,
    //     mobileNo: this.mobileNo,
    //     email: this.email,
    //   };
    //   requestDemo(data).then((res) => {
    //     if (res.status == 200) {
    //       this.isAuth = true;
    //     } else {
    //       alert("요청 실패");
    //     }
    //   });
    // },
  },
};
</script>
<style>
.inputArea {
  margin-top: 150px;
  width: 500px;
}
</style>
