<template>
  <div class="demoLeft">
    <h1>
      <router-link to="/">
        <img src="@/assets/images/reviewmind/logo2.png" alt="로고" />
      </router-link>
    </h1>
    <h2>리뷰 분석을 쉽게!</h2>
    <p>간단한 인증으로 리뷰마인드를 이용해보세요</p>
    <div class="inputArea">
      <div class="cover bg">
        <input type="text" v-model="company" placeholder="회사명" />
      </div>
      <div class="cover bg">
        <input type="text" v-model="name" placeholder="이름" />
      </div>
      <div class="cover bg">
        <input type="text" v-model="email" placeholder="이메일을 입력해주세요" />
      </div>
      <div class="cover bg">
        <input type="text" v-model="mobileNo" placeholder="핸드폰번호를 입력해주세요" />
        <!-- <button @click="requestCertNumber">인증번호발송</button> -->
      </div>
      <!-- <div v-show="veyfyCodeVisible" class="cover bg">
        <input
          type="text"
          v-model="certNumber"
          placeholder=" 인증번호 입력를 입력해주세요"
        />
      </div> -->
      <div v-show="verifyCodeVisible" class="cover bg">
        <input type="text" v-model="certNumber" placeholder=" 인증번호 입력를 입력해주세요" />
        <button @click="verifyNumber">확인</button>
      </div>

      <button type="button" @click="submit">Watch Demo</button>
    </div>
  </div>
</template>
<script>
import { requestDemo } from "@/api/index";
import { eventBus } from "@/main";
import { createCertNumber, verifyMobile } from "@/api/member";
import Cookies from "js-cookie";
import { format } from "@/mixins/format";

export default {
  mixins: [format],

  data() {
    return {
      company: "",
      name: "",
      mobileNo: "",
      email: "",
      verifyCodeVisible: false,
      certNumber: "",
      verify: false,
    };
  },
  methods: {
    requestCertNumber() {
      if (this.mobileNo == "") {
        return alert("번호를 입력해주세요.");
      }
      let data = {
        mobileNo: this.mobileNo,
      };
      this.verifyCodeVisible = true;
      createCertNumber(data).then((res) => {
        if (res.status == 200) {
          alert(res.data.message);
        } else {
          alert(res.data.message);
        }
      });
    },
    verifyNumber() {
      let data = {
        mobileNo: this.mobileNo,
        certNumber: this.certNumber,
      };
      verifyMobile(data).then((res) => {
        if (res.data.status == 200) {
          this.verify = true;
          alert(res.data.message);
        } else {
          this.verify = false;
          alert(res.data.message);
        }
      });
    },
    submit() {
      if (this.company == "") {
        return alert("회사명을 입력해주세요.");
      } else if (this.name == "") {
        return alert("이름을 입력해주세요.");
      } else if (this.email == "") {
        return alert("이메일을 입력해주세요.");
      } else if (this.emailValidate(this.email) == false) {
        return alert("이메일 형식이 올바르지 않습니다.");
      } else if (this.mobileNo == "") {
        return alert("핸드번호를 입력해주세요.");
      } else if (this.mobileNumberCheck(this.mobileNo) == false) {
        return alert("휴대폰번호 형식이 맞지 않습니다.");
      }
      // else if (this.verify == "") {
      //   return alert("핸드폰 인증을 입력해주세요.");
      // }
      let data = {
        company: this.company,
        name: this.name,
        mobileNo: this.mobileNo,
        email: this.email,
      };
      Cookies.set("DemoVerify", true);
      eventBus.$emit("closeModal", true);

      requestDemo(data).then((res) => {
        if (res.status == 200) {
          // Cookies.set("DemoVerify", true);
          // eventBus.$emit("closeModal", true);
        } else {
          alert("다시 시도해주세요");
        }
      });
    },
  },
};
</script>
<style>
.inputArea {
  margin-top: 150px;
  width: 500px;
}
</style>
